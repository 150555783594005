export const imports = {
  'pages/button.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "pages-button" */ 'pages/button.mdx'
    ),
  'pages/icon.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "pages-icon" */ 'pages/icon.mdx'
    ),
  'pages/drawer.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "pages-drawer" */ 'pages/drawer.mdx'
    ),
  'pages/colors.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "pages-colors" */ 'pages/colors.mdx'
    ),
  'pages/inline-dialog.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "pages-inline-dialog" */ 'pages/inline-dialog.mdx'
    ),
  'pages/input.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "pages-input" */ 'pages/input.mdx'
    ),
  'pages/select.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "pages-select" */ 'pages/select.mdx'
    ),
  'pages/tooltip.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "pages-tooltip" */ 'pages/tooltip.mdx'
    ),
  'pages/introduction.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "pages-introduction" */ 'pages/introduction.mdx'
    ),
  'pages/dialog.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "pages-dialog" */ 'pages/dialog.mdx'
    ),
}
